import * as React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from './SignUp';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />}/>
        <Route path="/signup" element={<SignUp />}/>

        {/*
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
      */}
      </Routes>
    </Router>
  );
}



function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      {/*
      <ProductHowItWorks />
      <ProductCTA />
      <ProductSmokingHero />
      */}
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(App);
