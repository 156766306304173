import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/images/equation_nonlinear.svg"
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6lt" sx={{ my: 5 }}>
                Advanced Geostatistics
              </Typography>
              <Typography variant="h5lt">
                {
                  'Non linear geostatistics and simulations can quantify'
                }

                {
                  ' both the geological and grade uncertainty, helping you make better decisions to minimize risks.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/images/nn.svg"
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6lt" sx={{ my: 5 }}>
                Artificial Intelligence
              </Typography>
              <Typography variant="h5lt">
                {
                  'Statistical learning uncovers non-trivial relationships within '
                }

                {'your dataset that could support mine and metallurgical planning.'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/images/automate.svg"
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6lt" sx={{ my: 5 }}>
                Automation
              </Typography>
              <Typography variant="h5lt">
                {'Custom automated workflows tailored to your current processes '}
                {'increase your team\'s productivity and let them focus on '}
                {'improving orebody knowledge.'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
