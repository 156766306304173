import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import AppBar from '../components/AppBar';
import Toolbar from "../components/Toolbar";
import Typography from "../components/Typography";


function AppAppBar() {

  return (

      <AppBar position="fixed" component='section'>
        <Toolbar  sx={{ justifyContent: 'space-between'}} >
          <Box  sx={{ flex: 1 }} />
          {/*
          <Link
          variant="h6"
          underline="none"
          color="inherit"
          href="/premium-themes/onepirate/"
          sx={{ fontSize: 24 }}
          >
          {'minerAI'}
          </Link>
        */}
        <Box 
         sx={{
           position: 'absolute',
           left: 10,
           right: 0,
           top: 0,
           bottom: 0,
           width: '200px',
           backgroundSize: '100%',
           backgroundPosition: 'center',
           backgroundImage: `url(/static/images/LOGO_TEXT.png)`,
           backgroundRepeat: 'no-repeat'
         }}
         />
          {/*
          <Link
          color="inherit"
          variant="h6"
          underline="none"
          href="/premium-themes/onepirate/sign-in/"
          sx={rightLink}
          >
          {'Sign In'}
          </Link>
        */}

            <Link to={"/signup"}>
            <Typography
              underline="none"
              variant="h6lt"
            >
              {"Contact Us"}
            </Typography>
            </Link>

        </Toolbar>
      </AppBar>

  );
}



export default AppAppBar;
